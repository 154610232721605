import React from "react"
import Layout from "../components/Layout"
import HeroLanding from "../components/HeroLanding"
import ListMattress from "../components/ListMattress"
import Seo from "../components/Seo/Seo"
import { graphql } from 'gatsby'

export const query = graphql`
  query ($location: String, $locale: String){
    datoCmsLocation(websiteUrl: {eq: $location}) {
      homepageSeoMeta {
        title
        description
        image {
          url(imgixParams: {fit: "crop", w: "1200", h: "675"})
        }
      }
      homepageTitle
      homepageHeroHeadline
      homepageHeroContentNode {
        childMarkdownRemark {
          html
        }
      }
      homepageHeroImage {
        gatsbyImageData(
          imgixParams: {fit: "crop", h: "400", w: "1600", colors: 10, auto: "format", bri: 30, con: -70}
          placeholder: BLURRED
        )
      }
      compareSidebarInfoNode {
        childMarkdownRemark {
          html
        }
      }
      country {
        countryName
      }
      flagImage {
        gatsbyImageData(
          imgixParams: {colors: 10, h: "30", w: "30", fit: "crop", auto: "format", mask: "ellipse"}
          placeholder: BLURRED
        )
      }
    }
    allDatoCmsMattress(
        filter: {
            locale: {eq: $locale}, 
            location: {
                websiteUrl: {eq: $location}
            }
        }, 
        sort: {
            fields: name, 
            order: ASC
            }
        ) {
        nodes {
            name
            slug
            location {
                websiteUrl
            }
            mattressImage {
                gatsbyImageData(
                    imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                    placeholder: BLURRED
                )
            }
            id
        }
    }
    datoCmsTranslation(locale: {eq: $locale}) {
        details
        compare
        slugMattress
        addTitle
        listMattressText

        home
        mattresses
        aboutUs
        guides
        countries
        contactUs
        cookiePrivacyPolicy
        accept
        learnMore
        company
        region
        
        altDetails
        altExternalLink
        altFlag
        altHero
        altIconCheck
        altIconDown
        altIconPlus
        altIconTimes
        altLogo
        altPortrait
        altPromotion
        activateDarkMode
        activateLightMode
    }
  }`

// markup
const Landing = ({ data, pageContext }) => {

  const meta_title = data.datoCmsLocation.homepageSeoMeta.title
  const meta_description = data.datoCmsLocation.homepageSeoMeta.description
  const meta_image = data.datoCmsLocation.homepageSeoMeta.image.url

  return (
    <Layout
      locale={pageContext.locale}
      queryTranslation={data.datoCmsTranslation}
      countryCode={pageContext.countryCode}
      footerText={data.datoCmsLocation.compareSidebarInfoNode}
      countryName={data.datoCmsLocation.country.countryName}
      flagImage={data.datoCmsLocation.flagImage}
    >
      <Seo
        title={meta_title}
        description={meta_description}
        image={meta_image}
      />

      <main>
        <HeroLanding
          gatsbyImage={data.datoCmsLocation.homepageHeroImage}
          title={data.datoCmsLocation.homepageTitle}
          headline={data.datoCmsLocation.homepageHeroHeadline}
          content={data.datoCmsLocation.homepageHeroContentNode}
        />

        <ListMattress
          className="bg-zone-3 py-12 px-4 xl:px-0"
          container={true}
          countryCode={pageContext.countryCode}
          queryMattress={data.allDatoCmsMattress}
        />
      </main>
    </Layout>
  )
}

export default Landing
